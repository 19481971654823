import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import ExternalLayout from './layouts/ExternalLayout';
import Layout from './layouts/Layout';
import AccountConfirmPage from './pages/AccountConfirmPage';
import AccountPage from './pages/AccountPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import OfferWallPage from './pages/OfferWallPage';
import PlayPage from './pages/PlayPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ShoppingPage from './pages/ShoppingPage';
import SignUpPage from './pages/SignUpPage';
import SurveysPage from './pages/SurveysPage';
import WatchPage from './pages/WatchPage';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setAuthenticated(true);
        }
      })
      .catch(() => setAuthenticated(false));
  }, []);

  Hub.listen('auth', (message) => {
    switch (message.payload.event) {
      case 'signIn':
      case 'autoSignIn':
        setAuthenticated(true);
        break;
      case 'signOut':
        setAuthenticated(false);
        break;
    }
  });

  return (
    <HashRouter>
      {authenticated ? (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path='/offerwall' element={<OfferWallPage />} />
            <Route path='/shopping' element={<ShoppingPage />} />
            <Route path='/watch' element={<WatchPage />} />
            <Route path='/surveys' element={<SurveysPage />} />
            <Route path='/play' element={<PlayPage />} />
            <Route path='/account' element={<AccountPage />} />
          </Route>
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<ExternalLayout />}>
            <Route id='login' index element={<LoginPage />}  />
            <Route id='login' path='/login' element={<LoginPage />} />
            <Route id='signup' path='/signup' element={<SignUpPage />} />
            <Route id='confirmAccount' path='/confirm-account' element={<AccountConfirmPage />} />
            <Route id='forgotPassword' path='/forgot-password' element={<ForgotPasswordPage />} />
            <Route id='resetPassword' path='/reset-password' element={<ResetPasswordPage />} />
          </Route>
        </Routes>
      )}
    </HashRouter>
  );
}

export default App;
