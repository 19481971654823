import { Auth } from "aws-amplify";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ForgotPasswordForm from "src/components/forms/ForgotPasswordForm";
import { useAppDispatch } from "src/hooks";
import { setAccountId } from "src/slices/UserSlice";

function ForgotPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [alert, setAlert] = useState<string | undefined>(undefined);

  const handleSubmit = async (id: string) => {
    try {
      setAlert(undefined);
      await Auth.forgotPassword(id);
      dispatch(setAccountId(id));
      navigate('/reset-password');
    } catch (error) {
      console.error('failed to initiate forgot password flow');
      setAlert('The e-mail you entered is not valid, check your information and try again');
    }
  }

  return (
    <div className="row h-100">
      <div className="col-12 d-flex align-items-center justify-content-center">
        <div className="card w-100" style={{maxWidth: 400}}>
          <div className="card-body">
            <h2 className="text-primary">Forgot Password</h2>
            <p className="text-muted">Enter your account e-mail to recover your password.</p>
            {alert && <div className="alert alert-danger alert-dismissible">
              <button type="button" className="btn-close" onClick={() => setAlert(undefined)}></button>
              {alert}
            </div>}
            <ForgotPasswordForm onSubmit={handleSubmit} />
            <div className="mt-4">
              <Link to="/login">Return to login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;