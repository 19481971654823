import { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { LoginModel, LoginSchema } from 'src/models/LoginModel';
import PasswordInput from '../elements/PasswordInput';
import TextInput from '../elements/TextInput';

interface LoginFormProps {
  onSubmit: (data: LoginModel) => void
}

function LoginForm({ onSubmit }: LoginFormProps) {
  const [ id, setId ] = useState('');
  const [ password, setPassword ] = useState('');
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = LoginSchema.safeParse({ id, password });
    if (result.success) {
      onSubmit(result.data);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        id='emailInput'
        onChange={setId}
        label='Account ID'
        type='email'
        schema={LoginSchema.shape.id}
        placeholder='E-mail address or phone number'
        groupClass='mb-2'
      />
      <PasswordInput
        id='passwordInput'
        onChange={setPassword}
        label='Password'
        schema={LoginSchema.shape.password}
        placeholder='Password'
        groupClass='mb-3'
      >
        <Link to="/forgot-password" style={{fontSize: 12}}>Forgot password?</Link>
      </PasswordInput>
      <button className="btn btn-lg btn-primary">Sign In</button>
      <Link className="btn btn-link" to="/signup" >Create an account</Link>
    </form>
  );
}

export default LoginForm;