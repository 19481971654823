import { ChangeEvent, FocusEvent, HTMLInputTypeAttribute, useState } from "react";
import { z } from "zod";

interface TextInputProps {
  id: string,
  label?: string,
  type?: HTMLInputTypeAttribute,
  onChange?: (value: string) => void,
  schema?: z.ZodString,
  equalTo?: string,
  equalToMessage?: string,
  placeholder?: string,
  validFeedback?: string,
  helpText?: string,
  groupClass?: string,
}

function TextInput({id, label, type, onChange, placeholder, ...props}: TextInputProps) {
  const [groupClass, setGroupClass] = useState<'' | 'has-success' | 'has-danger'>('');
  const [inputClass, setInputClass] = useState<'' | 'is-valid' | 'is-invalid'>('');
  const [feedbackClass, setFeedbackClass] = useState<undefined | 'valid-feedback' | 'invalid-feedback'>(undefined);
  const [validationMessage, setValidationMessage] = useState<string | undefined>(undefined);

  const setValidation = (isValid: boolean) => {
    if (isValid) {
      setGroupClass('has-success');
      setInputClass('is-valid');
      setFeedbackClass('valid-feedback');
      setValidationMessage(props.validFeedback);
    } else {
      setGroupClass('has-danger');
      setInputClass('is-invalid');
      setFeedbackClass('invalid-feedback');
    }
  };

  const handleChange = ({target}: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(target.value);
  };

  const handleBlur = ({target}: FocusEvent<HTMLInputElement, Element>) => {
    let isValid = true;
    let message = '';
    if (onChange) onChange(target.value);
    if (props?.schema) {
      const result = props.schema.safeParse(target.value);
      isValid = result.success && isValid;
      message = !result.success
        ? result.error.errors[0].message
        : '';
    }
    if (props.equalTo) {
      isValid = target.value === props.equalTo && isValid;
      message = target.value !== props.equalTo
        ? props.equalToMessage ?? 'This field must match the field above'
        : '';
    }
    setValidationMessage(message);
    setValidation(isValid);
  };

  return (
    <div className={`form-group ${groupClass} ${props.groupClass}`}>
      {label && <label htmlFor={id} className="form-label">{label}</label>}
      <input
        id={id}
        type={type ?? 'text'}
        onChange={handleChange}
        onBlur={handleBlur}
        className={`form-control ${inputClass}`}
        aria-describedby={`${id}Help`}
        placeholder={placeholder}
      />
      {(feedbackClass && validationMessage) && <div className={feedbackClass}>{validationMessage}</div>}
      {props.helpText && <small id={`${id}Help`} className="form-text text-muted">{props.helpText}</small>}
    </div>
  );
}

export default TextInput;