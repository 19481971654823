import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUpForm from "src/components/forms/SignUpForm";
import { useAppDispatch } from "src/hooks";
import { SignUpModel } from "src/models/SignUpModel";
import { setAccountId } from "src/slices/UserSlice";

function SignUpPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [alert, setAlert] = useState<string | undefined>(undefined);

  const handleSubmit = async (data: SignUpModel) => {
    try {
      setAlert(undefined);
      await Auth.signUp({
        username: data.email,
        password: data.password,
        autoSignIn: {
          enabled: true,
        },
      });
      dispatch(setAccountId(data.email));
      navigate('/confirm-account');
    } catch (error) {
      console.error('failed to sign up');
      setAlert('The ID and password combination that you entered is not valid. Check your information and try again');
    }
  };

  return (
    <div className="row h-100">
      <div className="col-12 d-flex align-items-center justify-content-center">
        <div className="card w-100" style={{maxWidth: 400}}>
          <div className="card-body">
            <h2 className="text-primary">Sign Up For Free</h2>
            <p className="text-muted">
              Fill in the information below to get started. Earn <strong className="text-success">10,000 Points</strong> today after you complete your profile and provide a valid US postal address.
            </p>
            {alert && <div className="alert alert-danger alert-dismissible">
              <button type="button" className="btn-close" onClick={() => setAlert(undefined)}></button>
              {alert}
            </div>}
            <SignUpForm onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpPage;