import { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import TextInput from '../elements/TextInput';

interface ForgotPasswordFormProps {
  onSubmit: (id: string) => void
}

function ForgotPasswordForm({ onSubmit }: ForgotPasswordFormProps) {
  const [ id, setId ] = useState('');
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = z.string().email().safeParse(id);
    if (result.success) {
      onSubmit(result.data);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        id='emailInput'
        onChange={setId}
        label='E-mail Address'
        type='email'
        schema={z.string().email()}
        placeholder='Account e-mail address'
        groupClass='mb-3'
      />
      <button className="btn btn-lg btn-primary">Submit</button>
      <Link className="btn btn-link" to="/login" >Return to login</Link>
    </form>
  );
}

export default ForgotPasswordForm;