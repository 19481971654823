import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Auth } from "aws-amplify";

function PageHeader() {
  const [greeting, setGreeting] = useState('Hey there');
  const [name, setName] = useState('');
  const [balance] = useState('50,000');

  const getGreeting = (hour: number) => {
    if (hour >= 12 && hour < 17) {
      return 'Good afternoon';
    } else if ((hour >= 17 && hour < 24) || (hour >= 0 && hour < 2)) {
      return 'Good evening';
    } else {
      return 'Good morning';
    }
  }

  useEffect(() => {
    setGreeting(getGreeting(DateTime.now().hour));
    Auth.currentAuthenticatedUser().then(({ attributes }) => {
      console.log(attributes);
      setName(attributes.given_name);
    })
  }, []);

  return (
    <div className="py-3">
      <h2 className="text-white">{greeting}, {name}!</h2>
      <p className="text-white">Current Balance: <strong>{balance}</strong> points</p>
    </div>
  );
}

export default PageHeader;