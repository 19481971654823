import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdwallOffer } from "src/API";
import getAdwallOffers from "src/services/adwall-service";

function HomePage() {
  const [offers, setOffers] = useState<(AdwallOffer | null)[]>([]);
  const navigate = useNavigate();
  const handleSignOut = async () => {
    await Auth.signOut();
    navigate('/login');
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(({attributes}) => {
        getAdwallOffers({
          subid1: attributes.sub,
          limit: 24,
        }).then((results) => {
          if (results) {
            setOffers(results);
          }
        })
      });
  }, []);

  

  return (
    <div>
      {offers.map((offer) => <p>{offer?.name}</p>)}
      <button className="btn btn-secondary" onClick={handleSignOut} type="button">Sign Out</button>
    </div>
  );
}

export default HomePage;