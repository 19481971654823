import { GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { ListAdwallOffersQuery, QueryListAdwallOffersQueryInput } from "src/API";
import { listAdwallOffers } from "src/graphql/queries";


const getAdwallOffers = async (query: QueryListAdwallOffersQueryInput) => {
  const offers = await API.graphql<GraphQLQuery<ListAdwallOffersQuery>>(
    {
      query: listAdwallOffers,
      variables: { query }
    }
  );
  return offers.data?.listAdwallOffers?.offers;
};

export default getAdwallOffers;