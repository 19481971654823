import { Link, RouteObject } from "react-router-dom";

function HeaderNav() {
  const routes: RouteObject[] = [];
  const match = (name: string) => routes.some(r => r.id === name) ? 'active' : '';
  return(
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/img/beaker_trim_dark.png" className="me-2" alt="" height="30" />
          <span style={{verticalAlign: 'middle'}}>OfferTheory</span>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarColor03">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <Link className={`nav-link ${match('home')}`} to="/">Home
                <span className="visually-hidden">(current)</span>
              </Link>
            </li>
            <li className="nav-item dropdown">
              { /*eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
              <a className={`nav-link dropdown-toggle ${match('offers')}`} data-bs-toggle="dropdown" role="button">Offers</a>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/offerwall">OfferWall</Link>
                <Link className="dropdown-item" to="/shopping">Shopping</Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${match('surveys')}`} to="/surveys">Surveys</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${match('watch')}`} to="/watch">Watch</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${match('play')}`} to="/play">Play</Link>
            </li>
          </ul>
          <form className="d-flex">
            <div className="input-group">
              <input className="form-control" type="search" placeholder="Search" />
              <button className="btn btn-primary" type="submit">
                <i className="bi bi-search"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </nav>
  );
}

export default HeaderNav;