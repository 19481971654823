import { Outlet } from "react-router-dom";

function ExternalLayout() {
  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-8 col-md-6 col-lg-8"></div>
        <div className="col-12 col-md-6 col-lg-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ExternalLayout;