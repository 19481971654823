import { Outlet } from "react-router-dom";
import HeaderNav from "src/components/HeaderNav";
import OffCanvas from "src/components/OffCanvas";
import PageHeader from "src/components/PageHeader";

function Layout() {
  return (
    <div id="layoutOuter" className="container-fluid p-0">
      <div id="layoutInner" className="container pt-sm-2 min-vh-100 pb-4">
        <div className="row">
          <HeaderNav />
        </div>
        <div className="row">
          <PageHeader />
        </div>
        <div className="row">
          <div className="col-12">
            <Outlet />
          </div>
        </div>
      </div>
      <OffCanvas />
    </div>
    
  );
};

export default Layout;