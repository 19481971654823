import { z } from 'zod';
import passwordFieldSchema from './PasswordSchema';

export const ResetPasswordSchema = z.object({
  id: z.string({
    required_error: 'You must enter your email or phone number to sign in'
  }).email({
    message: 'You must enter a valid email address',
  }),
  code: z.string({
    required_error: 'You must enter the code to confirm your account'
  }).length(6, {
    message: 'The code is 6 characters'
  }),
  password: passwordFieldSchema,
});

export type ResetPasswordModel = z.infer<typeof ResetPasswordSchema>;