import { FormEvent, useState } from "react";
import { Link } from "react-router-dom";
import { SignUpModel, SignUpSchema } from "src/models/SignUpModel";
import PasswordInput from "../elements/PasswordInput";
import TextInput from "../elements/TextInput";

interface SignUpFormProps {
  onSubmit: (data: SignUpModel) => void
}

function SignUpForm({onSubmit}: SignUpFormProps) {
  const [id, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = SignUpSchema.safeParse({ email: id, password });
    if (result.success && password === passwordConfirm && acceptedTerms) {
      onSubmit(result.data);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextInput
        id='emailInput'
        onChange={setEmail}
        label='E-mail address'
        type='email'
        schema={SignUpSchema.shape.email}
        placeholder='E-mail address'
        groupClass='mb-2'
      />
      <PasswordInput
        id='passwordInput'
        onChange={setPassword}
        label='Password'
        schema={SignUpSchema.shape.password}
        placeholder='Password'
        groupClass='mb-2'
      />
      <PasswordInput
        id='passwordConfirmInput'
        onChange={setPasswordConfirm}
        label='Confirm password'
        placeholder='Password (again)'
        groupClass='mb-3'
        equalTo={password}
        equalToMessage="Must match the password entered above"
      />
      <div className="form-group mb-3">
        <div className="form-check">
          <input
            id="privacyPolicyInput"
            className="form-check-input"
            type="checkbox"
            checked={acceptedTerms}
            onChange={(e) => {
              setAcceptedTerms(!acceptedTerms);
            }}
          />
          <label className="form-check-label" htmlFor="privacyPolicyInput">
            I agree to the <Link to="/privacy-policy">Privacy Policy</Link>
          </label>
        </div>
      </div>
      <button className="btn btn-lg btn-primary">Sign Up</button>
      <Link className="btn btn-link" to="/login" >Return to login</Link>
    </form>
  );
}

export default SignUpForm;