import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  accountId?: string
}

const initialState: UserState = {};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAccountId: (state, {payload}: PayloadAction<string>) => {
      state.accountId = payload;
    },
    clearAccountId: state => {
      state.accountId = undefined;
    }
  }
});

export const { setAccountId, clearAccountId } = userSlice.actions;

export default userSlice.reducer;