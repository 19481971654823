/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAdwallOffers = /* GraphQL */ `
  query ListAdwallOffers($query: QueryListAdwallOffersQueryInput!) {
    listAdwallOffers(query: $query) {
      offers {
        offer_id
        name
        description
        requirements
        credit_delay
        image_url
        click_url
        payout
        currency_count
        featured_global
        featured_profile
        epc
        conversion_rate
        category_id
        creative_id
        creative_filename
        creative_url
      }
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      city
      country
      lineOne
      lineTwo
      lobId
      zipCode
      userId
      state {
        code
        name
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
      addressStateCode
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        city
        country
        lineOne
        lineTwo
        lobId
        zipCode
        userId
        state {
          code
          name
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
        addressStateCode
      }
      nextToken
    }
  }
`;
export const addressesByUserId = /* GraphQL */ `
  query AddressesByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        city
        country
        lineOne
        lineTwo
        lobId
        zipCode
        userId
        state {
          code
          name
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
        addressStateCode
      }
      nextToken
    }
  }
`;
export const getBalance = /* GraphQL */ `
  query GetBalance($userId: String!) {
    getBalance(userId: $userId) {
      userId
      currentBalance
      lifetimeBalance
      createdAt
      updatedAt
    }
  }
`;
export const listBalances = /* GraphQL */ `
  query ListBalances(
    $userId: String
    $filter: ModelBalanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBalances(
      userId: $userId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userId
        currentBalance
        lifetimeBalance
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOfferCategory = /* GraphQL */ `
  query GetOfferCategory($id: ID!) {
    getOfferCategory(id: $id) {
      id
      name
      offers {
        items {
          categoryId
          clicks
          completes
          conversionRate
          description
          disclaimer
          entryUrl
          epc
          externalId
          incentive
          lifetimeIncentive
          lifetimeRevenue
          name
          primaryImageUrl
          providerId
          revenue
          secondaryImageUrl
          status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOfferCategories = /* GraphQL */ `
  query ListOfferCategories(
    $id: ID
    $filter: ModelOfferCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOfferCategories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        offers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOffer = /* GraphQL */ `
  query GetOffer($id: ID!) {
    getOffer(id: $id) {
      categoryId
      clicks
      completes
      conversionRate
      description
      disclaimer
      entryUrl
      epc
      externalId
      incentive
      lifetimeIncentive
      lifetimeRevenue
      name
      primaryImageUrl
      providerId
      revenue
      secondaryImageUrl
      status
      provider {
        id
        name
        offers {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listOffers = /* GraphQL */ `
  query ListOffers(
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOffers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        categoryId
        clicks
        completes
        conversionRate
        description
        disclaimer
        entryUrl
        epc
        externalId
        incentive
        lifetimeIncentive
        lifetimeRevenue
        name
        primaryImageUrl
        providerId
        revenue
        secondaryImageUrl
        status
        provider {
          id
          name
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const offersByCategoryId = /* GraphQL */ `
  query OffersByCategoryId(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offersByCategoryId(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        categoryId
        clicks
        completes
        conversionRate
        description
        disclaimer
        entryUrl
        epc
        externalId
        incentive
        lifetimeIncentive
        lifetimeRevenue
        name
        primaryImageUrl
        providerId
        revenue
        secondaryImageUrl
        status
        provider {
          id
          name
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const offersByProviderId = /* GraphQL */ `
  query OffersByProviderId(
    $providerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOfferFilterInput
    $limit: Int
    $nextToken: String
  ) {
    offersByProviderId(
      providerId: $providerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        categoryId
        clicks
        completes
        conversionRate
        description
        disclaimer
        entryUrl
        epc
        externalId
        incentive
        lifetimeIncentive
        lifetimeRevenue
        name
        primaryImageUrl
        providerId
        revenue
        secondaryImageUrl
        status
        provider {
          id
          name
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaypalPaymentLog = /* GraphQL */ `
  query GetPaypalPaymentLog($id: ID!) {
    getPaypalPaymentLog(id: $id) {
      paypalPaymentId
      transactionId
      userId
      id
      createdAt
      updatedAt
    }
  }
`;
export const listPaypalPaymentLogs = /* GraphQL */ `
  query ListPaypalPaymentLogs(
    $filter: ModelPaypalPaymentLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaypalPaymentLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        paypalPaymentId
        transactionId
        userId
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const paypalPaymentLogsByUserId = /* GraphQL */ `
  query PaypalPaymentLogsByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPaypalPaymentLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paypalPaymentLogsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        paypalPaymentId
        transactionId
        userId
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPostcardLog = /* GraphQL */ `
  query GetPostcardLog($id: ID!) {
    getPostcardLog(id: $id) {
      verified
      lobPostcardId
      postcardCode
      userId
      id
      createdAt
      updatedAt
    }
  }
`;
export const listPostcardLogs = /* GraphQL */ `
  query ListPostcardLogs(
    $filter: ModelPostcardLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPostcardLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        verified
        lobPostcardId
        postcardCode
        userId
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postcardLogsByUserId = /* GraphQL */ `
  query PostcardLogsByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPostcardLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postcardLogsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        verified
        lobPostcardId
        postcardCode
        userId
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($id: ID!) {
    getProvider(id: $id) {
      id
      name
      offers {
        items {
          categoryId
          clicks
          completes
          conversionRate
          description
          disclaimer
          entryUrl
          epc
          externalId
          incentive
          lifetimeIncentive
          lifetimeRevenue
          name
          primaryImageUrl
          providerId
          revenue
          secondaryImageUrl
          status
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProviders = /* GraphQL */ `
  query ListProviders(
    $id: ID
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProviders(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        offers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      activityId
      message
      points
      revenue
      sourceTransactionId
      userId
      activity {
        id
        completedAt
        offerName
        payoutPoints
        postbackUrl
        providerId
        providerOfferId
        userId
        provider {
          id
          name
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        activityId
        message
        points
        revenue
        sourceTransactionId
        userId
        activity {
          id
          completedAt
          offerName
          payoutPoints
          postbackUrl
          providerId
          providerOfferId
          userId
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByActivityId = /* GraphQL */ `
  query TransactionsByActivityId(
    $activityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByActivityId(
      activityId: $activityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        activityId
        message
        points
        revenue
        sourceTransactionId
        userId
        activity {
          id
          completedAt
          offerName
          payoutPoints
          postbackUrl
          providerId
          providerOfferId
          userId
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const transactionsByUserId = /* GraphQL */ `
  query TransactionsByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        activityId
        message
        points
        revenue
        sourceTransactionId
        userId
        activity {
          id
          completedAt
          offerName
          payoutPoints
          postbackUrl
          providerId
          providerOfferId
          userId
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsState = /* GraphQL */ `
  query GetUsState($code: String!) {
    getUsState(code: $code) {
      code
      name
      createdAt
      updatedAt
    }
  }
`;
export const listUsStates = /* GraphQL */ `
  query ListUsStates(
    $code: String
    $filter: ModelUsStateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsStates(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserActivity = /* GraphQL */ `
  query GetUserActivity($id: ID!) {
    getUserActivity(id: $id) {
      id
      completedAt
      offerName
      payoutPoints
      postbackUrl
      providerId
      providerOfferId
      userId
      provider {
        id
        name
        offers {
          nextToken
        }
        createdAt
        updatedAt
      }
      transactions {
        items {
          activityId
          message
          points
          revenue
          sourceTransactionId
          userId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserActivities = /* GraphQL */ `
  query ListUserActivities(
    $id: ID
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserActivities(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        completedAt
        offerName
        payoutPoints
        postbackUrl
        providerId
        providerOfferId
        userId
        provider {
          id
          name
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userActivitiesByProviderId = /* GraphQL */ `
  query UserActivitiesByProviderId(
    $providerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userActivitiesByProviderId(
      providerId: $providerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        completedAt
        offerName
        payoutPoints
        postbackUrl
        providerId
        providerOfferId
        userId
        provider {
          id
          name
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userActivitiesByUserId = /* GraphQL */ `
  query UserActivitiesByUserId(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userActivitiesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        completedAt
        offerName
        payoutPoints
        postbackUrl
        providerId
        providerOfferId
        userId
        provider {
          id
          name
          createdAt
          updatedAt
        }
        transactions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserDemographic = /* GraphQL */ `
  query GetUserDemographic($id: ID!) {
    getUserDemographic(id: $id) {
      answer
      providerId
      question
      userId
      provider {
        id
        name
        offers {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listUserDemographics = /* GraphQL */ `
  query ListUserDemographics(
    $filter: ModelUserDemographicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDemographics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        answer
        providerId
        question
        userId
        provider {
          id
          name
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userDemographicsByUserIdAndProviderId = /* GraphQL */ `
  query UserDemographicsByUserIdAndProviderId(
    $userId: String!
    $providerId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserDemographicFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDemographicsByUserIdAndProviderId(
      userId: $userId
      providerId: $providerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        answer
        providerId
        question
        userId
        provider {
          id
          name
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
