import { Auth } from "aws-amplify";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ConfirmAccountForm from "src/components/forms/ConfirmAccountForm";
import { useAppSelector } from "src/hooks";
import { AccountConfirmModel } from "src/models/AccountConfirmModel";

function AccountConfirmPage() {
  const navigate = useNavigate();
  const accountId = useAppSelector(state => state.user.accountId);
  const [alert, setAlert] = useState<string | undefined>(undefined);

  const handleSubmit = async (data: AccountConfirmModel) => {
    try {
      setAlert(undefined);
      await Auth.confirmSignUp(data.id, data.code);
      navigate('/');
    } catch (error) {
      console.error('failed to sign in');
      setAlert('The ID and password combination that you entered is not valid. Check your information and try again');
    }
  }

  return (
    <div className="row h-100">
      <div className="col-12 d-flex align-items-center justify-content-center">
        <div className="card w-100" style={{maxWidth: 400}}>
          <div className="card-body">
            <h2 className="text-primary">Confirm Account</h2>
            <p className="text-muted">Enter the verification code sent to you via e-mail. If you don't see the e-mail message make sure to check your junk/spam folders just in case.</p>
            {alert && <div className="alert alert-danger alert-dismissible">
              <button type="button" className="btn-close" onClick={() => setAlert(undefined)}></button>
              {alert}
            </div>}
            <ConfirmAccountForm accountId={accountId} onSubmit={handleSubmit} />
            <div className="mt-4">
              <Link to="/login">Return to login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountConfirmPage;