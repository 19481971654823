import { z } from 'zod';
import passwordFieldSchema from './PasswordSchema';

export const SignUpSchema = z.object({
  email: z.string({
    required_error: 'E-mail is a required field'
  }).email({
    message: 'You must enter a valid e-mail address'
  }),
  password: passwordFieldSchema,
});

export type SignUpModel = z.infer<typeof SignUpSchema>;
