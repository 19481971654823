import { z } from 'zod';

export const LoginSchema = z.object({
  id: z.string({
    required_error: 'You must enter your email or phone number to sign in'
  }).min(1, {
    message: 'You must enter your email or phone number to sign in'
  }),
  password: z.string({
    required_error: 'You must enter your password to sign in'
  }).min(1, {
    message: 'You must enter your password to sign in'
  }),
});

export type LoginModel = z.infer<typeof LoginSchema>;