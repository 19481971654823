import { FormEvent, useState } from "react";
import PasswordSchema from "src/models/PasswordSchema";
import PasswordInput from "../elements/PasswordInput";

interface NewPasswordFormProps {
  onSubmit: (password: string) => void
}

function ResetPasswordForm({onSubmit}: NewPasswordFormProps) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = PasswordSchema.safeParse(password);
    if (result.success && password === passwordConfirm) {
      onSubmit(result.data);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PasswordInput
        id='passwordInput'
        onChange={setPassword}
        label='Password'
        schema={PasswordSchema}
        placeholder='Password'
        groupClass='mb-2'
      />
      <PasswordInput
        id='passwordConfirmInput'
        onChange={setPasswordConfirm}
        label='Confirm password'
        placeholder='Password (again)'
        groupClass='mb-3'
        equalTo={password}
        equalToMessage="Must match the password entered above"
      />
      <button className="btn btn-primary">Submit</button>
    </form>
  );
}

export default ResetPasswordForm;