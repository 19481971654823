import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NewPasswordForm from 'src/components/forms/NewPasswordForm';
import { LoginModel } from 'src/models/LoginModel';
import LoginForm from '../components/forms/LoginForm';

function LoginPage() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState<string | undefined>(undefined);
  const [newPassword, showNewPassword] = useState(false);
  const [user, setUser] = useState<any>(undefined);

  const handleLoginSubmit = async (data: LoginModel) => {
    try {
      setAlert(undefined);
      const userResult = await Auth.signIn(data.id, data.password);
      setUser(userResult);
      if (userResult.challengeName === 'NEW_PASSWORD_REQUIRED') {
        showNewPassword(true);
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('failed to sign in');
      setAlert('The ID and password combination that you entered is not valid. Check your information and try again');
    }
  }

  const handleNewPasswordSubmit = async (password: string) => {
    try {
      setAlert(undefined);
      await Auth.completeNewPassword(user, password);
      navigate('/');
    } catch (error) {
      console.error('failed to set new password');
      setAlert('The new password you tried to set is not valid. Please check your information and try again');
    }
  }

  return (
    <div className="row h-100">
      <div className="col-12 d-flex align-items-center justify-content-center">
        <div className="card w-100" style={{maxWidth: 400}}>
          {!newPassword && <div className="card-body">
            <h2 className="text-primary">Let's Get<br/>You Started</h2>
            <p className="text-muted">Sign in and start earning rewards now.</p>
            {alert && <div className="alert alert-danger alert-dismissible">
              <button type="button" className="btn-close" onClick={() => setAlert(undefined)}></button>
              {alert}
            </div>}
            <LoginForm onSubmit={handleLoginSubmit} />
          </div>}
          {newPassword && <div className="card-body">
            <h2 className="text-primary">Set a new password</h2>
            <p className="text-muted">Your account was created with a temporary password. Set a new, permanent password.</p>
            {alert && <div className="alert alert-danger alert-dismissible">
              <button type="button" className="btn-close" onClick={() => setAlert(undefined)}></button>
              {alert}
            </div>}
            <NewPasswordForm onSubmit={handleNewPasswordSubmit} />
          </div>}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;