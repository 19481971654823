import { FormEvent, useState } from "react";
import { AccountConfirmModel, AccountConfirmSchema } from "src/models/AccountConfirmModel";
import TextInput from "../elements/TextInput";

interface ConfirmAccountFormProps {
  accountId?: string;
  onSubmit: (data: AccountConfirmModel) => void
}

function ConfirmAccountForm({accountId, onSubmit}: ConfirmAccountFormProps) {
  const [code, setCode] = useState('');
  const [id, setId] = useState(accountId);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = AccountConfirmSchema.safeParse({id, code});
    if (result.success) {
      onSubmit(result.data);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {!accountId && <TextInput
        id="idInput"
        onChange={setId}
        label='E-mail Address'
        type='email'
        schema={AccountConfirmSchema.shape.id}
        placeholder="e-mail address used when signing up"
        groupClass="mb-2"
      />}
      <TextInput
        id='codeInput'
        onChange={setCode}
        label='Confirmation Code'
        type='text'
        schema={AccountConfirmSchema.shape.code}
        placeholder="6-digit code"
        groupClass='mb-3'
      />
      <button className="btn btn-primary">Submit</button>
      <button className="btn btn-link">Resend confirmation code</button>
    </form>
  );
}

export default ConfirmAccountForm;