import { z } from 'zod';

const PasswordSchema = z.string({
  required_error: 'Password is a required field',
}).regex(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/gm,
  { message: 'Password must be at least 8 characters and requires a number, a special character, a lowercase letter, and an uppercase letter.' },
);

export default PasswordSchema;
