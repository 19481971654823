import { FormEvent, useState } from "react";
import { ResetPasswordModel, ResetPasswordSchema } from "src/models/ResetPasswordModel";
import PasswordInput from "../elements/PasswordInput";
import TextInput from "../elements/TextInput";

interface ResetPasswordFormProps {
  accountId?: string;
  onSubmit: (data: ResetPasswordModel) => void
}

function ResetPasswordForm({accountId, onSubmit}: ResetPasswordFormProps) {
  const [code, setCode] = useState('');
  const [id, setId] = useState(accountId);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = ResetPasswordSchema.safeParse({id, code, password});
    if (result.success && password === passwordConfirm) {
      onSubmit(result.data);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {!accountId && <TextInput
        id="idInput"
        onChange={setId}
        label='E-mail Address'
        type='email'
        schema={ResetPasswordSchema.shape.id}
        placeholder="e-mail address used when signing up"
        groupClass="mb-2"
      />}
      <TextInput
        id='codeInput'
        onChange={setCode}
        label='Confirmation Code'
        type='text'
        schema={ResetPasswordSchema.shape.code}
        placeholder="6-digit code"
        groupClass='mb-3'
      />
      <PasswordInput
        id='passwordInput'
        onChange={setPassword}
        label='Password'
        schema={ResetPasswordSchema.shape.password}
        placeholder='Password'
        groupClass='mb-2'
      />
      <PasswordInput
        id='passwordConfirmInput'
        onChange={setPasswordConfirm}
        label='Confirm password'
        placeholder='Password (again)'
        groupClass='mb-3'
        equalTo={password}
        equalToMessage="Must match the password entered above"
      />
      <button className="btn btn-primary">Submit</button>
      <button className="btn btn-link" type="button">Resend confirmation code</button>
    </form>
  );
}

export default ResetPasswordForm;